import React from "react"
import Helmet from 'react-helmet'
import 'typeface-pt-sans';

export default ({ children }) => (

  <div>
    <Helmet>
      <meta charSet="utf-8" />
      <html lang="en" />

      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://joy.cards" />
      <meta property="og:title" content="More joy than a greeting card" />
      <meta property="og:description" content="Create group video cards for birthdays, Christmas, get well soons, farewells and other occassions where you want to give more joy than a greeting card." />
      <meta property="og:image" content="https://s3-ap-southeast-2.amazonaws.com/joy.cards/og-img.jpg" />
      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:url" content="https://joy.cards" />
      <meta property="twitter:title" content="More joy than a greeting card" />
      <meta property="twitter:site" content="@Joycards1" />
      <meta property="twitter:description" content="Create group video cards for birthdays, Christmas, get well soons, farewells and other occassions where you want to give more joy than a greeting card." />
      <meta property="twitter:image" content="https://s3-ap-southeast-2.amazonaws.com/joy.cards/og-img.jpg" />
      <meta name="google-site-verification" content="QDd3WdwWRaQGyv-VxJVwGDnCaFwMwRD7j-Jzy3Uk9S8" />
    </Helmet>

    <div className="site-wrapper">
      {children}
    </div>
  </div>
)
